import * as React from 'react'
import Layout from '../components/layout'
import {content, stelist, paragraph} from '../components/layout.module.css'


const AboutPage = () => {
  return (
    <Layout pageTitle="About the Australian Dictionary of Invisible Culture for Teachers (AusDICT)">
      <div className={content}>
        <h2>(An introduction to Standard Translatable English as it is used in this dictionary)</h2>

<p class={paragraph}>This website is a response to the now decades-old call for language teachers to incorporate teaching invisible culture and intercultural competence in their classrooms. </p>

<p class={paragraph}>Often in language classrooms, the conversation about ‘culture’ is one about ‘visible culture,’ or the kinds of culture which are aesthetically obvious. Visible culture usually includes food, clothing, religion, holidays, art, literature, or hobbies. But it often does not include the ‘invisible’ aspects of culture. That is to say, the values and assumptions which govern how we use words and phrases, and how we understand them. Some aspects of this invisible culture are taught regularly—teachers teach request routines, politeness, how to respond in a job interview and so on—but often only to a surface level (because of various constraints). It is much rarer to investigate what ‘being polite’ means—and why we think it’s important—in a language classroom. </p>

<p class={paragraph}>In researching methods and activities for teaching invisible culture, I was made aware of three things. First, I found that there was a distinct lack of materials for the Australian context. Second, available materials had little strategy or structure behind what they presented. Third, there was very little for teachers to build their knowledge, even if some materials were available for students. </p>

<p class={paragraph}>I (Lauren) created this resource—the Australian Dictionary of Invisible Culture for Teachers (AusDICT)— for teachers to address these issues. The dictionary is unique in several ways. First, it is specific to the Australian context, including many Australian words, concepts, and values. What you won’t find though, is an extensive list of Australian slang, such as ‘budgie smugglers,' ‘barbies,' or ‘bonzer.’ A few slang terms are included, but only when they are representative of some Australian value particularly important to learners of English in Australia, and illustrate how that value is played out or is talked about. For example, ‘mate,’ ‘bogan’ and ‘wanker’ are all included here precisely because they are representative of the different kinds of interactions and relationships which exist in Australian social contexts. </p>

<p class={paragraph}>Second, the AusDICT uses a specialised framework to systematically explain the kind of highly complex information that is invisible culture. This framework breaks down these complex ideas into smaller parts and presents them in simple, cross-translatable language so that despite the cultural content, the descriptions are uncoloured by a cultural lens. While the dictionary is aimed at you, the English teacher, I have used this cross-translatable language throughout each of the entries to help you articulate concepts to students using the principle of cross-translatable language so that, from the outset, the information is available to students from all language and educational backgrounds. </p>

<p class={paragraph}>Third, the dictionary is for you, the teacher, to learn about the complexities of invisible culture in Australian English, and to explore the ways these concepts can be broken down to reveal the inner workings of native speakers. If you are a native speaker of English, you may be aware of many of these interactional norms, but struggle to explain why, or how they then appear in conversation. If you are a non-native speaker of English, some of these may be familiar, and yet others may explain the reasoning behind certain behaviours you are familiar with.</p>

<p class={paragraph}>Unlike traditional dictionaries, what I have aimed to capture is not the words and phrases of Australian English, but the invisible culture itself. You will immediately notice that some of the entries in this dictionary do not resemble traditional kinds of headwords at all. (Following this introduction is a section on how to use this dictionary, which will guide you through finding and accessing the contents of these non-traditional kinds of entries.) You will find that the entries illustrate more than just the meaning of the word. For example, the entry for ‘mate’ elaborates on how a person thinks of their mate, not just that the word is like a ‘friend.’ </p>

<p class={paragraph}>Also, some entries are for particular kinds of values which aren’t usually talked about explicitly in Australian society, but which are of importance to understanding interactions and behaviours. One example of this is ‘personal autonomy’—an unusually formal term for the idea that Australians value being able to do things because we want to do them. </p>

<p class={paragraph}>Finally, some entries describe interactional norms—or ways of speaking about topics or approaching conversations to achieve some effect. Normally, people don’t talk about how they interact with people consciously, so these entries will seem unusual at first. However, they make up an essential part of language use and how language speakers negotiate relationships with others. These ways of interacting are more than just the phrases we use to achieve such goals (like saying ‘would you please close the door?’ as a request). They are the reasons why this kind of request is a polite one and therefore encompass all the different ways in which Australian English speakers use indirect requests. </p>

<p class={paragraph}>In this dictionary, I have used Anglo English as the basis of Standard Australian English. In describing interactional norms, many of these norms are generally applicable across other varieties of Anglo Englishes (such as American English or British English). However, all are used in Australia or are known in Australia as a norm. </p>

<p class={paragraph}>Of course, I am aware that Standard Australian English is a difficult concept to pin down. While many say it is the most general variety of English used in Australia, the variations across the country make it more difficult to define. </p>

<p class={paragraph}>The AusDICT is based on the idea that to present the kind of complex information students require, we need a framework. Here, the framework is called Standard Translatable English (STE) and is custom built for the purposes of this dictionary. It is based on Minimal English (Goddard (ed.) 2018), but is a version of Minimal English designed for language teaching contexts. STE has three fundamental principles: </p>

<ol><li>All explanations should be accessible to students from any language background. </li>
<li> All explanations should use only everyday words, and no complex concepts. </li>
<li> Insider perspectives of complex concepts are necessary to learn about culture. </li></ol>
<p class={paragraph}>The first and second principles are inherently intertwined. Both have to do with the kind of language that the explanations are written in. Like many learner dictionaries, this dictionary uses a defining vocabulary to limit the words which can be used in each entry. In STE, the focus is on using words and grammatical structures which are cross-translatable in as many languages as possible. Some of the naturalness of the English has to be sacrificed to achieve genuinely cross-translatable expressions, however. It can be expected that the more cross-translatable the phrasing, the less idiomatic it sounds. For STE, a balance has been struck between being generally cross-translatable (that is understandable to different language learner levels) and being idiomatic. </p>

<p class={paragraph}>STE is made up of 126 words which can usually be found in other languages. If you are familiar with learner dictionaries, such as the Longman Dictionary of Contemporary English (LDOCE), and their defining vocabularies, you may notice how much smaller STE is as a defining vocabulary than that of the 3000-word LDOCE. The main reason for this is that the defining vocabularies of many other dictionaries are based on word frequency in English, whereas STE is based on translatability. Because the words in the vocabulary are translatable and culture-neutral, they are most suited to explaining the complex cultural concepts which are the focus of this dictionary. Some words are so representative of hidden beliefs and attitudes that it is difficult for native speakers to see that they are culture-laden. However, because those words are so representative, they are remarkably common in the language and therefore make it into the 3000 most common words of English. The current dictionary includes a module where some of these cultural keywords are elaborated. </p>

<p>The 126 words of STE in alphabetical order are:</p>
 <ol class={stelist}>
<li>a drink</li>
<li>a long time</li>
<li>a short time</li>
<li>above</li>
<li>after</li>
<li>alcohol</li>
<li>all</li>
<li>arm</li>
<li>as</li>
<li>australia</li>
<li>bad</li>
<li>be (someone or something)</li>
<li>be (somewhere)</li>
<li>because</li>
<li>before</li>
<li>behind</li>
<li>below</li>
<li>big</li>
<li>body</li>
<li>both </li>
<li>bring</li>
<li>buy</li>
<li>can</li>
<li>cars</li>
<li>cheek</li>
<li>child</li>
<li>cities</li>
<li>class</li>
<li>classrooms</li>
<li>close (to something)</li>
<li>clothes</li>
<li>country</li>
<li>day</li>
<li>die</li>
<li>do</li>
<li>drink</li>
<li>during</li>
<li>eat</li>
<li>else</li>
<li>eyes</li>
<li>face</li>
<li>far</li>
<li>feel</li>
<li>few</li>
<li>food</li>
<li>for some time</li>
<li>good</li>
<li>government</li>
<li>hair</li>
<li>hand</li>
<li>happen</li>
<li>have</li>
<li>head</li>
<li>hear</li>
<li>here</li>
<li>house</li>
<li>how (to do something)</li>
<li>I</li>
<li>if</li>
<li>inside</li>
<li>kind</li>
<li>know</li>
<li>laugh</li>
<li>like (something is like something else)</li>
<li>little</li>
<li>live</li>
<li>living things</li>
<li>look (good)</li>
<li>many</li>
<li>maybe</li>
<li>me</li>
<li>moment</li>
<li>more</li>
<li>move</li>
<li>much</li>
<li>name</li>
<li>near</li>
<li>night</li>
<li>not</li>
<li>nothing</li>
<li>now</li>
<li>one</li>
<li>or</li>
<li>other</li>
<li>part</li>
<li>people</li>
<li>place</li>
<li>quickly</li>
<li>say</li>
<li>see</li>
<li>shoes</li>
<li>sick</li>
<li>side</li>
<li>small</li>
<li>smile</li>
<li>some</li>
<li>someone</li>
<li>something</li>
<li>student</li>
<li>talk</li>
<li>teacher</li>
<li>the same</li>
<li>themselves</li>
<li>there is</li>
<li>thing</li>
<li>think</li>
<li>this</li>
<li>time</li>
<li>touch</li>
<li>trees</li>
<li>true</li>
<li>two</li>
<li>university</li>
<li>very</li>
<li>want</li>
<li>way</li>
<li>wear</li>
<li>week</li>
<li>when</li>
<li>where</li>
<li>why</li>
<li>words</li>
<li>work</li>
<li>write</li>
<li>year</li>
<li>you</li></ol>
<p class={paragraph}>As you read through this dictionary, and encounter different entries, you will notice how much longer the entries are than in a standard dictionary. Traditionally, dictionaries only capture what they refer to as semantic information, in contrast, this dictionary aims to encapsulate a much broader sense of semantic—including what people know about the word—and some pragmatic information (how people use the word). Importantly, the STE entries capture language speakers’ thoughts, attitudes, and feelings towards these words, or pragmatic cues. You will find that some parts of each entry reflect what the speaker intends by the word, what they are trying to communicate, or how they are thinking. In some cases, parts of the definitions indicate how Australians more broadly perceive the word or phrase, or how they think about the person who uses it. </p>

<p class={paragraph}>At first glance, the entries referring to attitudes and interactional norms may strike you as being prescriptive, especially as not everyone interacts in the same way, nor do people behave in the same way in all situations. It should be kept in mind that interactional norms, values, and attitudes do not represent rules of interaction, or rules about how a person has to think. Instead, they represent parts of the range of what is considered ‘normal’ in Australian English—a range which exists as a kind of socially constructed understanding of how people interact. It is perfectly possible (and common) to act outside of this range for a variety of reasons. Sometimes it is to encourage a particular effect (e.g., it’s an emergency, so you ignore the script of making indirect requests and order someone to close the door); or sometimes it is to communicate a particular emotional or physical state (e.g., you want someone to know how angry you are, so you do not follow the script for expressing your opinion calmly). The violation of these accepted norms usually comes with a signal that something out of the ordinary, to be paid attention to, is happening in this interaction. Individuals too can vary on their adherence to these scripts. The scripts themselves are written in such a way that plenty of individual variation is allowed for—it is never dictated what kind of ‘bad’ feeling a person is feeling, just that it is within the range of ‘bad’. </p>

<p class={paragraph}>As there are so many non-traditional entries in this Dictionary, choosing entry titles and an organisation was an important element of design. Early on, it became apparent that alphabetisation was not the most ideal structure, as it was not most fitting with the way people would search for concepts in the Dictionary. Instead, I have organised the entries into modules which are reflective of different domains and topics. These domains were chosen as they align with topics that may be taught in ESL classes, especially those aimed at general life skills for migrants. Recognising that cultural concepts are inherently intertwined, I have made extensive use of cross-referencing on each entry, to help you, the reader, to navigate between related entries. </p>

<p class={paragraph}>STE is not only a defining vocabulary for this dictionary but is also an approach to explaining concepts which can be used more broadly. Because the STE framework focuses on the internal perspectives of language users, it can be used to break down any idea into simpler language. It can be used to explain new vocabulary items to students, to elaborate on cultural practices, to compare and contrast Australian English terms and cultural practices with students’ mother tongue terms and culture, to explain the difference between synonyms, to highlight what influences differences in register, and so much more. STE as a framework is flexible, and it is up to you as a teacher to choose the parts of it that are most useful to your students. Using STE in classroom contexts is a skill which takes time to develop. I hope that by reading this dictionary, you will become more familiar with the language that makes up STE, as well as the way that concepts can be broken down. And finally, I hope that you will be able to improve on each and every entry. </p>

<p class={paragraph}>As with all large projects, the AusDICT was not created in a vacuum. I would like to give my most sincere thanks to each and every one of the teachers across Australia who has contributed to this research at each stage of the project. The conversations we have had, questions you have asked, ideas you have shared, feedback you have given, and stories you have told have been invaluable in shaping this dictionary into its current form. I can never thank you enough. </p>

<p class={paragraph}>Lauren Sadow </p>

      </div>
    </Layout>
  )
}

export default AboutPage